.share-links {
    position: relative;
    &__breadcrumb {
        display: flex;
        justify-content: space-between;
        left: 60%;
        padding-left: 40px;
        position: absolute;
        top: 75px;
        z-index: 100;
    }
    &__static {
        cursor: default;
        display: flex;
        margin-right: 20px;
    }
    &__breadcrumb-list {
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding-left: 0;
        li {
            margin-right: 20px;
        }
        li:hover,
        li > a:hover {
            color: $color-blue-grey;
            cursor: pointer;
        }
    }
}
@include media-breakpoint-between(md, lg) {
    .share-links {
        &__breadcrumb {
            display: flex;
            justify-content: space-between;
            left: 20px;
            list-style: none;
            position: absolute;
            top: 20px;
            z-index: 50;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .share-links {
        &__breadcrumb {
            display: flex;
            justify-content: space-between;
            left: -27px;
            list-style: none;
            position: absolute;
            top: 20px;
            z-index: 50;
        }
    }
}

.swiper {
    &__image {
        background-position: $position-center;
        background-repeat: $repeat;
        background-size: $cover-size;
        margin-right: 25px;
        img {
            align-items: center;
        }
    }
    &__image-logs {
        align-content: center;
        background-position: $position-center;
        background-repeat: $repeat;
        background-size: $cover-size;
        display: flex;
        height: 250px;
        margin: auto 0;
        width: 200px;
        img {
            align-items: center;
        }
    }
}

.swiper-slide.swiper__image-logs {
    align-items: center;
    border-right: 0.01px solid $border-logo;
    height: 250px;
    justify-content: center;
}
.nav-swiper {
    left: 8%;
    max-height: 450px;
    width: 100%;
}
.swiper-slide.swiper__image.swiper-slide-active {
    width: unset;
}
.swiper-slide.swiper__image {
    align-content: center;
    display: flex;
    margin: auto 0;
}

.swiper-wrapper {
    max-height: 538px;
    padding-bottom: 100px;
    position: relative;
    z-index: 0;
}

.swiper-container {
    overflow-x: hidden;
    overflow-y: visible;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 60%;
    right: auto;
    top: 580px;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    left: 66%;
    top: 580px;
}
.border-custom {
    border-right: 1px solid $color-grey;
    height: 251px;
}
.swiper-photo {
    top: 42px;
}
@include media-breakpoint-between(md, lg) {
    .swiper-slide.swiper__image-logs {
        height: 160px;
    }
    .border-custom {
        border-right: 1px solid $color-grey;
        height: 180px;
    }
}
@include media-breakpoint-between(xs, sm) {
    .swiper-slide.swiper__image-logs {
        height: 109px;
        img {
            height: 132px;
            width: 132px;
        }
    }
    .border-custom {
        border-right: 1px solid $color-grey;
        height: 151px;
    }
    .nav-swiper {
        left: -28%;
        max-height: 150px;
        width: 130%;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 213px;
        right: $unset;
        top: 489px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        left: auto;
        right: 90px;
        top: 489px;
    }
    .swiper-photo {
        top: 26px;
    }
}

.read-more-fixed {
    bottom: 30px;
    position: absolute;
    &::after {
        content: url("../assets/images/icons-svg/cards/right.svg");
        margin-left: 10px;
        vertical-align: text-top;
    }
}
.read-more-fixed-dark {
    bottom: 30px;
    position: absolute;
    &::after {
        content: url("../assets/images/icons-svg/search/right.svg");
        margin-left: 10px;
        vertical-align: text-top;
    }
}
.heigh-container {
    min-height: 530px;
}

.cards {
    height: 100%;
    min-height: 950px;
    padding-bottom: 240px;
    position: relative;

    &--related-pages {
        min-height: 670px;
        padding-bottom: 77px;
    }
}
.cards__center {
    padding-top: 200px;
    position: relative;
}
.cards__body {
    padding-top: 107px;
    position: relative;
}

.cards__wrapper {
    padding-top: 79px;
    position: relative;
}
.card-primary {
    @include blur-polyfill($color-gradient-blue, 14px);

    border-radius: $border-card;
    color: $color-white;
    display: block;
    margin-bottom: 180px;
    max-width: 398px;
    min-height: 488px;
    padding: 35px 0 0 35px;
    position: relative;
    z-index: 1;
    &__p {
        opacity: 0.8;
    }
    &__content {
        min-height: 110px;
        width: 85%;
    }
    &__content--team {
        height: 200px;
    }
    &__content-slider {
        min-height: 100px;
        padding-top: 26px;
        p {
            opacity: 0.8;
        }
    }
    &--slider {
        @include blur-polyfill($color-gradient-blue, 14px);

        color: $color-white;
        margin: 0;
        min-height: 510px;
        padding: 22px;
        position: relative;
        z-index: 5;
    }

    &--team {
        height: 292px;
        margin-bottom: 60px;
        max-width: 500px;
        min-height: $unset;
        padding: 41px 86px 35px 39px;
        z-index: 5;
    }
    .read-more {
        bottom: 10px;
        color: $color-white;
        display: none;
        position: absolute;
        &::after {
            content: url("../assets/images/icons-svg/cards/right.svg");
            margin-left: 8px;
            vertical-align: text-top;
        }
    }
    &:hover {
        .read-more {
            display: block;
        }
    }
    &__img {
        bottom: -50px;
        left: 35px;
        max-height: 528px;
        max-width: 398px;
        position: absolute;
        right: 0;
        img {
            border-radius: $border-card 0 $border-card $border-card;
            height: 100%;
            width: 100%;
        }
    }
    &__img-slider {
        max-height: 230px;
        max-width: 355px;
        img {
            border-radius: $border-card;
            display: block;
            height: 100%;
            object-fit: cover;
            object-position: 70% 70%;
            transform: scale3d(1.05, 1.05, 1.05);
            transform-origin: center center;
            transition: transform 0.3s ease-out;
            width: 100%;
        }
    }
}
.card-primary--slider:hover {
    min-height: 520px;
    .read-more {
        display: block;
        &:hover {
            color: $color-white;
        }
    }
    .card-primary__img-slider {
        img {
            transform: scale3d(1, 1, 1);
            transform-origin: center center;
            transition: transform 0.3s ease-out;
        }
    }
}
.card-secondary {
    margin-bottom: 196px;
    max-width: 384px;
    z-index: 5;
    &__content {
        @include blur-polyfill($color-gradient-blue, 14px);

        border-radius: $border-card;
        bottom: -150px;
        height: 220px;
        left: 16px;
        padding: 26px 26px 24px 38px;
        position: absolute;
        width: 100%;
        z-index: 10;
        h2 {
            margin-bottom: 10px;
        }
    }
    &__clickable {
        display: block;
        max-width: 384px;
        position: relative;
    }
    &__img {
        max-height: 495px;
        max-width: 363px;
        position: relative;
        z-index: 4;
        img {
            border-radius: $border-card;
            height: 100%;
            width: 100%;
        }
    }
}
.card-photo {
    background-position: $position-center;
    background-repeat: $repeat;
    background-size: $cover-size;
    img {
        align-items: center;
        border-radius: $border-card;
        height: 100%;
    }
    &__design {
        max-height: 596px;
        max-width: 520px;
        img {
            border-radius: $border-card;
            height: 100%;
            width: 100%;
        }
    }
}
.card-contact {
    @include blur-polyfill($color-gradient-blue, 37px);

    border-radius: $border-card;
    display: none;
    padding: 32px 40px 5px;
    pointer-events: auto;
    z-index: 5;

    &.active {
        display: block;
    }
    &__title {
        margin-bottom: 53px;
        p {
            opacity: 0.8;
        }
    }
    &__p {
        margin-bottom: 31px;
        opacity: 0.8;
    }
    &__heading {
        margin-bottom: 10px;
        opacity: 1;
    }
}
.card-related {
    @include blur-polyfill($color-gradient-blue, 14px);

    border-radius: $border-card;
    color: $color-white;
    display: block;
    margin: 0 auto 180px;
    max-width: 318px;
    min-height: 322px;
    min-width: 254px;
    padding: 35px 0 0 35px;
    position: relative;
    z-index: 1;

    &__content {
        width: 85%;
    }

    &__img {
        bottom: -50px;
        left: 35px;
        max-height: 290px;
        max-width: 398px;
        position: absolute;
        right: 0;

        img {
            border-radius: $border-card 0 $border-card $border-card;
            height: 100%;
            width: 100%;
        }
    }
}

@include media-breakpoint-between(md, lg) {
    .card-secondary {
        &__img {
            margin-bottom: 177px;
            max-height: 395px;
            max-width: 303px;
            position: relative;
            z-index: 14;
        }
        &__content {
            height: 230px;
            left: 19px;
            min-height: 230px;
            padding: 21px 35px 24px 30px;
            top: 259px;
            width: 100%;
            z-index: 15;
        }
        &__clickable {
            max-width: 314px;
        }
    }
    .card-contact {
        &__title {
            margin-bottom: 30px;
        }
        &__p {
            margin-bottom: 21px;
        }
    }
    .card-photo {
        &__design {
            height: 492px;
            width: 354px;
        }
    }
    .card-primary {
        margin-bottom: 100px;
        max-width: 344px;
        min-height: 446px;
        &__content {
            width: 95%;
        }
        &--slider {
            height: 456px;
            margin: 0;
        }

        &--slider:hover {
            min-height: 456px;
        }
        &--team {
            min-height: 264px;
            padding: 41px 86px 35px 39px;
        }
    }
    .card-related {
        margin: 0 auto 160px;
        max-width: 304px;
        min-height: 300px;
    }
}
@include media-breakpoint-between(xs, sm) {
    .cards {
        padding-bottom: 0;
        padding-top: 200px;

        &--related-pages {
            padding-top: 0;
        }
        &__center {
            margin: 0 auto;
            padding-top: 50px;
            position: relative;
        }
        &__body {
            margin: 0 auto;
            position: relative;
        }
        .container-fluid {
            overflow: hidden;
        }
    }

    .card-primary {
        margin: 0 auto;
        margin-bottom: 150px;
        max-width: 323px;
        min-height: 400px;
        min-width: 236px;
        padding: 23px 29px;

        &__title {
            width: 77%;
        }
        &__img {
            bottom: -170px;
            margin-bottom: 100px;
            max-height: 261px;
            max-width: 290px;
        }
        &--slider {
            height: 456px;
            margin: 0 auto;
            max-width: 330px;
            min-width: $unset;
            padding: 22px;
            &:hover {
                min-height: 456px;
            }
        }
        &__content-slider {
            padding-top: 0;
        }

        &__img-slider {
            margin-bottom: 17px;
            max-height: 181px;
            max-width: 279px;
        }
        &--team {
            margin-bottom: 0;
            margin-top: 30px;
            max-height: 265px;
            min-height: 264px;
            padding: 28px 21px;
            h2 {
                margin-bottom: 0;
            }
            p {
                margin-bottom: 26px;
            }
        }
        &__content {
            width: 100%;
        }
        .read-more {
            display: block;
        }
    }
    .card-contact {
        border-radius: $border-min;
        padding: 20px 20px 12px;
        &__title {
            margin-bottom: 24px;
        }
        &__p {
            margin-bottom: 8px;
        }
        &__heading {
            margin-bottom: 5px;
        }
    }
    .card-photo {
        &__design {
            max-height: 292px;
            max-width: 254px;
        }
    }
    .card-secondary {
        margin: 0 auto;
        margin-bottom: -24px;
        &__img {
            height: 220px;
            margin-bottom: 150px;
            max-width: 288px;
            img {
                object-fit: cover;
                object-position: 100% 28%;
            }
        }
        &__content {
            left: 25px;
            max-height: 186px;
            max-width: 288px;
            min-height: 150px;
            padding: 16px 30px 2px 20px;
            top: 140px;
            h2 {
                margin-bottom: 8px;
            }
            p {
                opacity: 0.8;
                width: 95%;
            }
        }
    }
    .card-related {
        margin: 0 auto 100px;
        max-width: 284px;
        min-height: 270px;
        min-width: 236px;
        padding: 23px 29px;

        &__title {
            width: 100%;
        }
        &__img {
            max-height: 220px;
            max-width: 290px;
        }
    }
    .heigh-container {
        margin-bottom: 30px;
        min-height: 427px;
    }
}

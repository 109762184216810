

.accIcon {
    align-items: center;
    display: flex;
    float: right;
    height: 30px;
    justify-content: center;
    margin-left: 20px;
    pointer-events: none;
    right: 2.375rem;
    width: 30px;
}

.accIcon::before,
.accIcon::after {
    background-color: $color-blue-grey;
    content: '';
    position: absolute;
    transition: ease 0.3s all;
}

.accIcon::before {
    height: 20px;
    width: 2px;
}

.accIcon::after {
    height: 2px;
    width: 20px;
}
.accIcon.activeIcon.accIcon::before {
    transform: rotate(90deg);
}
.is-hidden {
    display: none;
}
.is-active {
    display: block;
}
.activetab {
    opacity: 1;
}
.inactivetab {
    opacity: 0.8;
}

.is-mobile {
    display: none;
}

.accordion-item {
    @supports (-moz-appearance:none) {
        border: $none;
    }

    border: 0.1px solid $theme-base;
    color: $color-black;
    position: relative;
    &__button {
        @include button-reset;

        @supports (-moz-appearance:none) {
            background-color: $color-gradient-acc;
        }

        align-items: center;
        background: $color-gradient-accordion;
        color: $color-blue-font;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 2.375rem;
        position: relative;
        text-align: left;
        width: 100%;
    }
    &__content {
        @supports (-moz-appearance:none) {
            background-color: $color-gradient-acc;
        }

        height: 0;
        overflow: hidden;
        position: relative;
    }

    &__text {
        padding: 2.375rem;
    }
    &[data-active="true"] {
        .accIcon::before {
            height: 0;
            width: 0;
        }

        @include media-breakpoint-up(lg) {
            border: 0.1px solid $color-blue-grey;

            &:first-child {
                border-top: 0;
            }
            &:last-child {
                border-radius: 0 0 $border-section $border-section;
            }
        }
    }
    &[data-active="true"] &__button {
        font-family: $roboto-bold;
        padding-bottom: 2.375rem;
    }

    &:not(:last-child) &__button {
        &::after {
            background-color: $color-paley-grey;
            bottom: 0;
            content: '';
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 97%;
        }
    }

    &[data-active="true"]:last-child &__button {
        &::after {
            background-color: $color-paley-grey;
            bottom: 0;
            content: '';
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 97%;
        }
    }
}
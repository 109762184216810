.section {
    &--contacts {
        padding: 2.75rem 0;
        position: relative;
        .picture__decoration-right-large  {
            right: 0;
            top: 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 7.8125rem 0;

            .container {
                position: relative;
            }

            .picture__decoration-right-large {
                transform: translate3d(15%, -15%, 0);
                transform-style: preserve-3d;
            }
        }
    }
}
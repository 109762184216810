.page-heading {
    overflow: hidden;
    position: relative;

    &--map {
        background: $color-blue-font;

        .map__content {
            margin: 0 auto;
            max-width: 945px;
            top: auto;

            .card-contact {
                position: relative;
            }

            @include media-breakpoint-between(md, lg) {
                max-width: 675px;
            }
        }

        .map-contacts {
            bottom: 0;
            margin-top: -1.5rem;
            padding: 0 0 3.125rem;
            position: relative;
            width: 100%;

            @include media-breakpoint-up(xl) {
                height: 50%;
                margin-top: 0;
                padding: 3rem 0 5.125rem;
                position: absolute;
            }
        }

        .picture__decoration-map {
            position: absolute;
        }

        @include media-breakpoint-up(md) {
            background: $theme-base;
        }
    }


    @include media-breakpoint-up(xl) {
        padding-top: 90px;

        .card-contact {
            max-width: 440px;
        }
    }
}
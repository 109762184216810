.page-title {
    &--contacts {
        background: $color-white;
        left: 0;
        padding: 2rem 0 3rem;
        position: relative;
        width: 100%;

        .bg--image {
            max-width: 100%;
        }

        @include media-breakpoint-up(xl) {
            background: $theme-base;
            max-height: 50%;
            position: absolute;
        }
    }
}

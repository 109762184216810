.form {
    $self: &;

    &--contacts {
        @include blur-polyfill($color-gradient-contact, 37px);

        border-radius: $border-card;
        margin: 0 auto;
        max-width: 945px;
        padding: 64px 115px 23px;
        position: relative;
        width: 100%;
        z-index: 5;

        #{$self}__title {
            margin-bottom: 37px;
        }

        #{$self}__success {
            color: $color-success-msg;
            font-family: $roboto;
            font-size: $font-small;
            min-height: 1.5em;
        }
        
        #{$self}__label {
            font-size: inherit;
        }

        #{$self}__button {
            border: $none;
            margin-bottom: 23px;
            outline: $none;
        }

        @include media-breakpoint-between(md, lg) {
            max-width: 675px;
            padding: 18px 20px 0;
        }
        @include media-breakpoint-between(xs, sm) {
            border-radius: $border-min;
            padding: 18px 20px 0;

            #{self}__title {
                margin-bottom: 25px;
            }
        }
        @include media-breakpoint-between(xs, lg) {
            #{$self}__button {
                margin-bottom: 0;
            }
        }
    }
}

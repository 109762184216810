.partners-slider {
    overflow: hidden;
    padding: 111px 0 94px;
    position: relative;
    h1 {
        margin: 0 auto;
        margin-bottom: 39px;
        max-width: 490px;
        text-align: center;
    }
}

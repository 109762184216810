.player {
    overflow: hidden;
    padding: 7% 9% 10% 10%;
    position: relative;
    &__up {
        background-image: $color-gradient-plyr;
        border-radius: $border-section;
        height: 100%;
        max-height: 600px;
        max-width: 1124px;
        opacity: 0.9;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 7;
    }
    &__bg {
        height: 100%;
        max-height: 600px;
        max-width: 1124px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
        img {
            border-radius: $border-section;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    &__content {
        height: 600px;
        margin: auto 0;
        max-width: 1124px;
        position: relative;
        width: 100%;
        z-index: 5;
    }
    &__container {
        max-width: 1510px;
        h1 {
            margin-bottom: 40px;
            width: 33%;
        }
    }
}
.play-button {
    cursor: pointer;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.blue-color {
    color: $color-blue;
}
.plyr {
    border-radius: $border-section;
    display: none;
    height: 633px;
    max-width: 1124px;
    position: relative;
    width: 100%;
    z-index: 5;
}
@include media-breakpoint-between(md, lg) {
        .player {
        padding: 0 5% 10% 10%;
        &__up,
        &__bg,
        &__content {
            max-height: 390px;
            max-width: 700px;
        }
        &__bg {
            height: 100%;
            max-height: 390px;
            max-width: 700px;
            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        &__container {
            padding-top: 80px;
            h1 {
                margin-bottom: 54px;
                width: 80%;
            }
        }
    }
    .play-button {
        height: 50px;
        width: 50px;
    }
    .plyr {
        height: 100%;
        width: 100%;
    }
}
@include media-breakpoint-between(xs, sm) {
    .player {
        padding: 0 10%;
        &__up,
        &__bg,
        &__content {
            max-height: 203px;
            max-width: 400px;
            width: 100%;
        }
        &__bg {
            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        &__container {
            padding: 61px 0 87px;
            h1 {
                margin-bottom: 22px;
                width: 80%;
            }
        }
    }
    .play-button {
        height: 40px;
        width: 40px;
    }
    .plyr {
        height: 100%;
        width: 100%;
    }
}

/* stylelint-disable declaration-property-value-allowed-list */
.google-map {
    border-radius: 0.75rem;
    height: 400px;
    width: 100%;
}
.page-heading--map {
    .google-map {
        background-image: url('../assets/images/map-placeholder.png');
        background-size: 100% 100%;
        border-radius: 0.3125rem;
        height: 440px;
        margin-left: auto;
        margin-top: -1.5rem;
        position: relative;
        width: calc(100% - #{($grid-gutter-width / 2)});
        z-index: 1;

        @include media-breakpoint-up(xl) {
            border-radius: 0.75rem;
            height: 800px;
            margin-top: 0;
            width: 60%;
        }
    }
}
/* stylelint-enable declaration-property-value-allowed-list */
.downloads {
    position: relative;
    &__pad {
        padding: 144px 0;
        position: relative;
    }
    &__img {
        max-height: 62px;
        max-width: 50px;
    }
}

@include media-breakpoint-up(md) {
    .downloads__pad {
        .row {
            margin-bottom: 55px;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .downloads {
        &__pad {
            max-width: 241px;
            padding: 100px 0 50px;
            .row {
                margin-bottom: 30px;
            }
        }
    }
}

* {
    box-sizing: border-box;
    font-family: $roboto;
    font-size: $font-base;
    word-break: break-word;
}
html {
    height: 100%;
}
body {
    overflow-x: hidden;
}
h1 {
    font-size: $font-xlarge;
}
h2 {
    font-size: $font-large;
}
p {
    font-size: $font-base;
}
a:hover {
    color: $unset;
    text-decoration: none;
}
.active-link {
    color: $color-blue-light;
    font-family: $roboto-bold;
}
.bg--light {
    background-color: $theme-base-primary;
    color: $color-blue-grey;
}
.bg--dark {
    background-color: $color-blue-font;
    color: $color-white;
}
.bg--none {
    background-color: $theme-base;
    color: $color-blue-grey;
}
.bg--image {
    max-width: 100vw;
    opacity: 0.5;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    img {
        height: 100%;
        width: 100%;
    }
}
.header-scroll {
    background-color: $color-white;
    color: $color-blue-grey;
    opacity: 0.95;
    .header__list {
        color: $color-blue-grey;
    }
}
.arrow-hoverHeader {
    background-color: $color-blue-grey;
}
.arrow-light {
    background-color: $color-white;
}
.arrow-dark {
    background-color: $color-blue-grey;
}
.arrow-opened {
    background-color: $color-blue-light;
}
.hideblock {
    display: none;
}
.hidesearch {
    display: none;
    opacity: 0;
    z-index: 0;
}
.hideopacity {
    opacity: 0;
}
.overflow-hide {
    overflow: hidden;
    position: relative;
}
.container-fluid {
    max-width: 90%;
}
.read-arrow {
    padding-left: 10px;
}
div.wagtail-userbar.wagtail-userbar--bottom-right {
    bottom: 1em;
    right: 7em;
}

@include media-breakpoint-between(md, lg) {
    h1 {
        font-size: $font-xlarge-t;
    }
    h2 {
        font-size: $font-medium;
    }
    p,
    li {
        font-size: $font-base-ip;
    }
    .bg--image {
        max-width: 100vw;
        opacity: 0.5;
        img {
            height: 100vh;
            object-fit: cover;
            object-position: 0 50%;
            opacity: 1;
            width: 100%;
        }
    }
    .header-scroll {
        opacity: 1;
    }
}
@include media-breakpoint-between(xs, sm) {
    h1 {
        font-size: $font-xlarge-ip;
    }
    h2 {
        font-size: $font-medium;
    }
    p,
    li {
        font-size: $font-base-ip;
    }
    .bg--image {
        opacity: 0.5;
        img {
            height: 100vh;
            object-fit: cover;
            object-position: 0 50%;
            opacity: 1;
            width: 100%;
        }
    }
    .justify-content-center {
        margin: 0 auto;
    }
    .header-scroll {
        opacity: 1;
    }
}

.accent-description {
    padding-bottom: 135px;
    padding-top: 270px;
    position: relative;
    &__sizer {
        margin: 0 auto;
        max-width: 67%;
        width: 100%;
    }
    &__important {
        text-decoration: underline;
    }
    &__list-point {
        margin-right: 18px;
    }
    ul {
        margin-left: 70px;
    }

    li::marker {
        color: $color-bullets;
        font-size: $font-large;
    }
}
@include media-breakpoint-between(md, lg) {
    .accent-description {
        padding-bottom: 95px;
        padding-top: 104px;
        &__sizer {
            max-width: 83%;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .accent-description {
        padding-bottom: 52px;
        padding-top: 84px;
        &__sizer {
            max-width: 93%;
        }
        &__list-point {
            width: 7px;
        }
        ul {
            margin-left: 0;
            padding-left: 20px;
        }
    }
}

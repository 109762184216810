.picture {
    &__decoration {
        left: 0;
        max-height: 293px;
        max-width: 324px;
        position: absolute;
        top: 0;
        &--about {
            left: 75px;
            max-height: 302px;
            max-width: 273px;
            top: 802px;
        }
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-map {
        left: 0;
        max-height: 293px;
        max-width: 324px;
        position: relative;
        top: 0;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-down {
        bottom: 438px;
        height: 200px;
        left: -411px;
        position: absolute;
        width: 200px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right {
        bottom: 300px;
        max-height: 442px;
        max-width: 786px;
        position: absolute;
        right: 25px;
        z-index: 0;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right-slide {
        bottom: 20px;
        position: absolute;
        right: 15px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right-top {
        max-height: 320px;
        max-width: 320px;
        position: absolute;
        right: 70px;
        top: 105px;
        z-index: 0;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right-center {
        bottom: 119px;
        left: 69%;
        max-height: 664px;
        max-width: 606px;
        position: absolute;
        width: 100%;
        z-index: 0;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right-large {
        bottom: 305px;
        height: 607px;
        position: absolute;
        right: 193px;
        width: 607px;
        z-index: 0;
        img {
            height: 100%;
            width: 100%;
        }
    }

    &__decoration-left-top {
        height: 116.43px;
        left: 13%;
        position: absolute;
        top: 237px;
        transform: translateX(-13%);
        width: 105.66px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-left-bottom {
        left: 0;
        max-height: 244px;
        max-width: 94px;
        position: absolute;
        top: 80%;
        z-index: 0;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-blur {
        backdrop-filter: blur(12px);
        background-image: $color-gradient-blueblur-card;
        border-radius: $border-card;
        height: 492px;
        left: 4%;
        position: absolute;
        top: 196px;
        width: 96%;
        z-index: 2;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-mobile {
        display: none;
    }
}
@include media-breakpoint-between(md, lg) {
    .picture {
        &__decoration {
            &--about {
                left: 15px;
                top: 636px;
            }
        }
        &__decoration-blur {
            left: 7%;
            max-height: 334px;
            max-width: 93%;
        }
        &__decoration-right-large {
            bottom: 497px;
            max-height: 505px;
            max-width: 505px;
            right: 0;
        }
        &__decoration-map {
            max-height: 200px;
            max-width: 200px;
        }
        &__decoration-right-center {
            bottom: 95px;
            height: 400px;
            left: 69%;
            width: 400px;
        }
        &__decoration-left-bottom {
            bottom: -91px;
            height: 165px;
            left: -5px;
            top: unset;
            width: 103px;
        }
        &__decoration-right-top {
            height: 220px;
            right: 0;
            top: 105px;
            width: 220px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        &__decoration-left-top {
            top: 70px;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .picture {
        &__decoration {
            max-height: 190px;
            max-width: 214px;

            &--about {
                display: none;
            }
        }
        &__decoration-map {
            display: none;
        }
        &__decoration-mobile {
            display: block;
            height: 171px;
            left: -36px;
            position: absolute;
            top: 343px;
            width: 172px;

            img {
                height: 100%;
                width: 100%;
            }
        }
        &__decoration-right-large {
            height: 235px;
            right: -97px;
            top: 36%;
            width: 235px;
        }
        &__decoration-right {
            bottom: $unset;
            height: 141px;
            right: 23px;
            top: 94px;
        }
        &__decoration-down {
            bottom: -140px;
            height: 400px;
            left: -150px;
            width: 400px;
        }
        &__decoration-left-top {
            height: 68px;
            top: 65.2px;
            width: 62px;
        }
        &__decoration-blur {
            height: 246px;
            left: 5%;
            max-width: 95%;
            top: 90px;
        }
        &__decoration-right-slide {
            display: none;
        }
        &__decoration-right-slide-mobile {
            height: 90px;
            position: absolute;
            right: 0;
            top: 318px;
            width: 80px;
            z-index: 1;
        }
        &__decoration-right-center {
            bottom: 45px;
            height: 211px;
            left: 70%;
            width: 198px;
        }
        &__decoration-right-top {
            height: 120px;
            right: 0;
            top: 105px;
            width: 120px;
        }
        &__decoration-left-bottom {
            bottom: -111px;
            height: 165px;
            left: -50px;
            top: unset;
            width: 63px;
            z-index: 0;
        }
    }
}

.search {
    // min-height: 100vh;
    padding: 162px 0 68px;
    &__heading {
        margin-bottom: 118px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 77px;
        max-height: 200px;
        max-width: 765px;
        min-height: 170px;
        padding-left: 20px;
    }
    &__content::before {
        background: $color-gradient;
        content: '';
        height: 100%;
        left: 0;
        max-height: 200px;
        min-height: 170px;
        position: absolute;
        top: 0;
        width: 1px;
    }
    &__not-found {
        min-height: 100vh;
        padding-top: 142px;
    }
}
@include media-breakpoint-between(xs, sm) {
    .search {
        overflow: hidden;
        padding: 26px 0 90px;
        &__not-found {
            min-height: unset;
            padding-top: 26px;
        }
        &__results {
            font-size: $font-xlarge-ip;
        }
        &__content::before {
            left: 15px;
        }
    }
}

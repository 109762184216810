.error {
    padding: 27px 0 0 107px;
    &__bg {
        margin: auto 0;
        max-height: 708px;
        max-width: 708px;
        position: relative;
        width: 100%;
        z-index: 1;
        img {
            height: 100%;
            width: 100%;
        }
    }

    &__title {
        h1 {
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 37px;
            opacity: 0.7;
        }
    }
}
@include media-breakpoint-between(md, lg) {
    .error {
        padding: 100px 0 0;
        &__bg {
            max-height: 500px;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .error {
        padding: 0;
        &__bg {
            margin: 0 auto;
            max-height: 492px;
            max-width: 392px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        &__title {
            margin-bottom: 50px;
            text-align: center;
            h1 {
                margin-bottom: 22px;
            }
        }
    }
}

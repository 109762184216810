.header {
    padding: 15px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;

    &__container {
        margin: 0 auto;
        max-width: 90%;
    }
    &__logo {
        display: block;
    }
    &__mobile {
        display: none;
    }
    &__row {
        margin: 0;
        padding: 0;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li > a:hover {
        color: $color-blue-light;
    }

    &__icons-mobile {
        display: none;
    }
    .arrow {
        background-image: url($chevron-nav-black);
        background-repeat: $repeat;
        float: right;
        height: 20px;
        margin-right: 10px;
        transition: all 0.3s;
        width: 20px;

        &.dark {
            background-image: url($chevron-nav-black);
        }
        &.white {
            background-image: url($chevron-nav-white);
        }
        &.active,
        &.active-arrow,
        &.active-arrow.active {
            background-image: url($chevron-nav-blue);
        }
    }
    &__list,
    &__list-section,
    &__list-subsection {
        display: block;
        position: relative;
        width: 100%;

        .active-link + .arrow {
            background-image: url($chevron-nav-blue);
        }
        &.hovered {
            color: $color-blue-light;
        }
        &:hover {
            span {
                color: $color-blue-light;
                z-index: 10;
            }
        }
        &::after {
            content: "";
            height: 50px;
            left: 0;
            position: absolute;
            top: 10px;
            width: 100%;
        }
    }
    &__list {
        color: $unset;
        padding: 0 20px;

        &:hover {
            .arrow {
                background-image: url($chevron-nav-blue);
            }
        }
    }
    &__nav__link {
        position: relative;
    }
    &__nav__link-sections {
        min-width: 250px;
        position: absolute;
        transform: scaleY(0);
        transform-origin: top;
        visibility: hidden;

        .arrow {
            background-position: $chevron-position;
            transform: rotate(270deg);
        }
    }
    &__list-section,
    &__list-subsection {
        color: $color-blue-grey;
    }

    &__nav__link-sections.open {
        background-color: $background-accent;
        border-top: 2px solid $color-blue-light;
        padding: 26px 0 26px 26px;
        top: 60px;
        transform: scaleY(1);
        transition: transform 0.26s ease-in-out;
        visibility: visible;
        li {
            margin-bottom: 26px;
            position: relative;
        }
        .nested-menu {
            color: $color-blue-grey;
            &.hovered {
                color: $color-blue-light;
            }
        }
    }
    &__nav__link-subsections {
        position: absolute;
        transform: scaleX(0);
        transform-origin: left;
        visibility: hidden;
    }
    &__nav__link-subsections.open {
        background-color: $background-accent;
        border-top: 2px solid $color-blue-light;
        display: block;
        left: 223px;
        min-width: 250px;
        padding: 26px;
        position: absolute;
        top: 0;
        transform: scaleX(1);
        transition: transform 0.26s ease-in-out;
        visibility: visible;
        width: 100%;

        li {
            margin-bottom: 14px;
            margin-top: 14px;
        }
    }
    .basket.non-active {
        cursor: default;
        opacity: 0;
    }
    .header__light-search,
    .header__light-themes,
    .header__dark-theme,
    .header__dark-themes {
        cursor: pointer;
        display: block;
        left: 90%;
        position: relative;
        z-index: 55;
    }
    .header__light-search.inactive,
    .desktop-trolley.inactive,
    .header__light-themes.inactive,
    .header__dark-theme.inactive,
    .header__dark-themes.inactive {
        display: none;
    }
}
@include media-breakpoint-up(lg) {
    .header {
        &__nav__link {
            &:hover {
                .header__nav__link-sections {
                    background-color: $background-accent;
                    border-top: 2px solid $color-blue-light;
                    padding: 26px 0 26px 26px;
                    top: 60px;
                    transform: scaleY(1);
                    transition: transform 0.26s ease-in-out;
                    visibility: visible;
                }
                .header__nav__link-sections li {
                    margin-bottom: 26px;
                }
            }
        }
    }
}
@include media-breakpoint-between(md, lg) {
    .header {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        top: 0;
        width: 100%;
        z-index: 999;

        .row {
            justify-content: space-between;
        }
        &__container {
            max-width: 100%;
            padding: 0 15px;
        }
        &__desktop {
            display: none;
            padding: 0;
        }
        &__mobile {
            display: none;
            padding: 0;
        }
        &__mobile.open {
            display: block;
            height: 100vh;
            position: relative;
        }
        &__list,
        &__list-section,
        &__list-subsection {
            width: 80%;
        }
        &__nav__link.has-subsection::after {
            content: url($chevron-nav-black);
            height: 20px;
            position: absolute;
            right: 4%;
            width: 20px;
        }
        &__nav__link {
            display: flex;
            flex-direction: column;
            margin-bottom: 14px;
            margin-top: 14px;
            padding: 0 20px;
            width: 100%;
        }
        &__list-section::after {
            content: none;
        }
        &__nav__link-sections {
            display: none;
        }
        &__nav__link-sections.open {
            background-color: $background-accent;
            border-top: 1px solid $color-blue-light;
            display: block;
            height: fit-content;
            margin-bottom: 15px;
            padding: 30px 0 0 36px;
            position: relative;
            top: 20px;
            width: 100%;
        }
        &__nav__link-section {
            position: relative;
            &.has-subsection::after {
                background-position: $chevron-position;
                content: url($chevron-nav-black);
                cursor: pointer;
                height: 20px;
                position: absolute;
                right: 4%;
                top: 0;
                width: 20px;
            }
        }
        &__nav__link-subsections {
            display: none;
        }
        &__nav__link-subsections.open {
            background-color: $background-accent;
            border: $unset;
            display: block;
            left: 0;
            margin: 30px 10px 50px;
            padding: 0;
            position: relative;
            top: 0;
            width: 70%;
        }

        &__mobile-trolley {
            img {
                position: absolute;
                right: 0;
                top: 4px;
            }
        }

        &__icons-desktop {
            display: none;
        }
        &__icons-mobile {
            display: block;
        }
        .mobile-search {
            padding: 15px 0;
        }
        &__search-option {
            background-color: $theme-base;
            border-color: $theme-base;
            position: absolute;
            right: 10px;
            top: 22%;
        }
        &__pad {
            display: flex;
            justify-content: unset;
        }
    }

    .moveright {
        left: 100px;
    }
}
@include media-breakpoint-between(xs, sm) {
    .header {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        top: 0;
        width: 100%;
        z-index: 999;

        &__container {
            max-width: 100%;
            padding: 0 15px;
        }
        &__desktop {
            display: none;
            padding: 0;
        }
        &__mobile {
            display: none;
            padding: 0;
        }
        &__mobile.open {
            display: block;
            height: 100vh;
            position: relative;
        }
        &__list,
        &__list-section,
        &__list-subsection {
            width: 80%;
        }
        &__nav__link {
            display: flex;
            flex-direction: column;
            margin-bottom: 14px;
            margin-top: 14px;
            padding: 0;
            width: 100%;
        }
        &__nav__link.has-subsection::after {
            content: url($chevron-nav-black);
            cursor: pointer;
            height: 20px;
            position: absolute;
            right: 4%;
            width: 20px;
        }
        &__list-section::after {
            content: none;
        }
        &__nav__link-sections {
            display: none;
        }
        &__nav__link-sections.open {
            background-color: $background-accent;
            border-top: 1px solid $color-blue-light;
            display: block;
            height: fit-content;
            margin-bottom: 15px;
            padding: 30px 0 0 36px;
            position: relative;
            top: 20px;
            width: 100%;
        }
        &__nav__link-section {
            position: relative;
            &.has-subsection::after {
                background-position: $chevron-position;
                content: url($chevron-nav-black);
                cursor: pointer;
                height: 20px;
                position: absolute;
                right: 4%;
                top: 0;
                width: 20px;
            }
        }
        &__nav__link-subsections {
            display: none;
        }
        &__nav__link-subsections.open {
            background-color: $background-accent;
            border: $unset;
            display: block;
            left: 0;
            margin: 30px 10px 45px;
            padding: 0;
            position: relative;
            top: 0;
            width: 90%;
        }

        &__icons-desktop {
            display: none;
        }
        &__mobile-trolley {
            img {
                position: absolute;
                right: 0;
                top: 4px;
            }
        }
        &__icons-mobile {
            display: block;
        }
        &__search-option {
            background-color: $theme-base;
            border-color: $theme-base;
            position: absolute;
            right: 7%;
            top: 13%;
        }
        .mobile-search {
            padding: 0 15px;
        }
        &__pad {
            display: flex;
            justify-content: unset;
        }
    }
}

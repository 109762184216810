.presentation-accent {
    height: 100%;
    max-height: 660px;
    position: relative;
    &__img {
        height: 800px;
        position: relative;
        width: 60%;
        z-index: 1;
        img {
            border-radius: 0 $border-section $border-section 0;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}
@include media-breakpoint-between(md, lg) {
    .presentation-accent {
        height: 100%;
        max-height: $unset;
        overflow: hidden;
        padding-bottom: 100px;
        position: relative;
        &__img {
            margin-bottom: 200px;
            width: 90%;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .presentation-accent {
        overflow: hidden;
        position: relative;
        &__img {
            max-height: 302px;
            width: 80%;

            img {
                border-radius: 0 0 $border-section 0;
                width: 100%;
            }
        }

    }
}

.carousel {
    overflow: hidden;
    padding:  140px 50px 140px 0;
    position: relative;
    &__mobile-direction {
        flex-direction: $unset;
    }
    &__content {
        @include blur-polyfill($color-gradient-blue, 7px);

        border-radius: $border-section;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        z-index: 99;
    }
    &__decoration-left {
        left: -252px;
        max-width: 608px;
        position: absolute;
        top: 200px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right {
        bottom: 0;
        height: 244px;
        position: absolute;
        right: 144px;
        width: 94px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right-center {
        bottom: 305px;
        position: absolute;
        right: 193px;
        z-index: 0;
    }
    .bullets {
        cursor: pointer;
        height: 25px;
        width: 25px;
        z-index: 999;
    }
    &__ellipse {
        position: relative;
        transition: all 0.4s ease-in-out;

        &.toIndex-2 {
            transform: rotate(59deg) translateY(-325px);
        }
        &.toIndex-3 {
            transform: rotate(90deg) translateY(-325px);
        }
        &.toIndex-4 {
            transform: rotate(125deg) translateY(-340px);
        }
        &.toIndex-5 {
            transform: rotate(156deg) translateY(-361px);
        }

        &--top {
            left: 546px;
            position: absolute;
            top: 45px;
        }
        &--top-r {
            left: 658px;
            position: absolute;
            top: 165px;
        }
        &--bottom-r {
            bottom: 35px;
            left: 522px;
            position: absolute;
        }
        &--bottom {
            bottom: 165px;
            left: 658px;
            position: absolute;
        }
        &--center {
            left: 704px;
            opacity: 1;
            position: absolute;
            top: 325px;
        }
        &--center-color {
            left: 355px;
            opacity: 1;
            position: absolute;
            top: 310px;
            transform: rotate(30deg) translateY(-333px);
            z-index: 10000;
        }
    }

    &__title {
        margin: auto 0;
        position: relative;
        h1 {
            margin-bottom: 26px;
        }
        p {
            margin-bottom: 37px;
            opacity: 0.8;
        }
    }
    &__background-center {
        margin: auto 0;
        max-height: 708px;
        max-width: 573px;
        img {
            border-radius: $border-section;
            height: 641px;
            object-fit: cover;
            object-position: 70% 0%;
            width: 100%;
        }
    }
}
.swiper-counter {
    color: $color-white;
    left: 29px;
    position: absolute;
    top: 22px;
    transform: rotate(-28deg);
}
.toIndex-2 > .swiper-counter {
    transform: rotate(-58deg);
}
.toIndex-3 > .swiper-counter {
    transform: rotate(-90deg);
}
.toIndex-4 > .swiper-counter {
    transform: rotate(-122deg);
}
.toIndex-5 > .swiper-counter {
    transform: rotate(-160deg);
}

.elipse {
    border: 2px solid $color-white;
    border-radius: $half-radius;
    height: 720px;
    left: -400px;
    margin: auto 0;
    position: relative;
    transform: rotate(0deg);
    transition: transform 0.7s linear;
    width: 720px;
    z-index: 999;
}

@include media('XL') {
    .carousel {
        &__decoration-left {
            left: -340px;
        }
    }
    .elipse {
        left: -478px;
    }
}

@include media-breakpoint-between(md, lg) {
    .carousel {
        padding: 0;
        padding-bottom: 180px;

        &__content {
            margin: 0 auto;
            max-width: 688px;
            min-height: 828px;
            top: 90px;
        }
        &__ellipse {
            height: 156px;
            &.toIndex-1 {
                transform: rotate(49deg) translate(-37px, -100px);
            }
            &.toIndex-2 {
                transform: rotate(21deg) translate(118px, -88px);
            }
            &.toIndex-3 {
                transform: rotate(21deg) translate(232px, -116px);
            }
            &.toIndex-4 {
                transform: rotate(21deg) translate(341px, -187px);
            }
            &.toIndex-5 {
                transform: rotate(22deg) translate(403px, -289px);
            }
            // 1 slide
            &--top {
                bottom: 58px;
                height: 20px;
                left: 115px;
                position: absolute;
                top: $unset;
                width: 20px;
            }
            &--center-color {
                bottom: -29px;
                height: 40px;
                left: 9%;
                position: absolute;
                top: $unset;
                transform: rotate(22deg) translateY(-108px);
                width: 40px;
                z-index: 9999;
            }
            // 2 slide
            &--top-r {
                bottom: 4px;
                height: 20px;
                left: 222px;
                position: absolute;
                top: $unset;
                width: 20px;
            }
            // 3 slide
            &--center {
                bottom: -15px;
                height: 20px;
                left: 333px;
                position: absolute;
                top: unset;
                width: 20px;
            }
            &--bottom {
                bottom: 13px;
                left: 461px;
                position: absolute;
            }
            &--bottom-r {
                bottom: 73px;
                left: 555px;
                position: absolute;
            }
        }
        &__title {
            padding: 46px;
            h1 {
                margin-bottom: 5px;
            }
        }
        &__mobile-direction {
            flex-direction: column-reverse;
        }
        &__background-center {
            margin: auto 0;
            max-height: 308px;
            max-width: 428px;
            img {
                border-radius: $border-section;
                height: 308px;
                margin-left: 15px;
                object-fit: cover;
                object-position: 100% 28%;
                width: 428px;
            }
        }
        &__decoration-right {
            bottom: -15px;
            height: 165px;
            position: absolute;
            right: 45px;
            width: 65px;
        }
        &__decoration-left {
            left: 50%;
            top: -390px;
            transform: translateX(-50%);
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
    .elipse {
        height: 680px;
        left: 0;
        top: -492px;
        transform: rotate(0);
        width: 680px;
    }
    .swiper-container.swiper-carousel {
        padding: 100px 0 50px;
    }
    .swiper-counter {
        transform: rotate(335deg);
    }
    .toIndex-2 > .swiper-counter {
        transform: rotate(-22deg);
    }
    .toIndex-3 > .swiper-counter {
        transform: rotate(-25deg);
    }
    .toIndex-4 > .swiper-counter {
        transform: rotate(-22deg);
    }
    .toIndex-5 > .swiper-counter {
        transform: rotate(-25deg);
    }
}
@include media-breakpoint-between(xs, sm) {
    .carousel {
        padding: 0;
        padding-bottom: 180px;
        &__decoration-left {
            left: 50%;
            top: -195px;
            transform: translateX(-50%);
            width: 349px;
            img {
                width: 100%;
            }
        }
        &__content {
            margin: 0 auto;
            max-height: 728px;
            max-width: 360px;
            top: 90px;
        }
        &__ellipse {
            height: 156px;

            &.toIndex-2 {
                transform: rotate(21deg) translate(118px, -126px);
            }
            &.toIndex-3 {
                transform: rotate(21deg) translate(212px, -198px);
            }
            &.toIndex-4 {
                transform: rotate(21deg) translate(118px, -126px);
            }
            &.toIndex-5 {
                transform: rotate(22deg) translateY(-110px);
            }
            // 1 slide
            &--top {
                bottom: 15px;
                height: 20px;
                left: 117px;
                position: absolute;
                top: $unset;
                width: 20px;
            }
            &--center-color {
                bottom: -77px;
                height: 40px;
                left: 12%;
                position: absolute;
                // right: 498px;
                top: $unset;
                transform: rotate(22deg) translateY(-110px);
                width: 40px;
                z-index: 9999;
            }
            // 2 slide
            &--top-r {
                bottom: -11px;
                height: 20px;
                left: 234px;
                position: absolute;
                top: $unset;
                width: 20px;
            }
            // 3 slide
            &--center {
                bottom: 16px;
                height: 20px;
                left: 342px;
                position: absolute;
                top: unset;
                width: 20px;
            }
        }
        &__title {
            padding: 46px;
            h1 {
                margin-bottom: 5px;
            }
        }
        &__mobile-direction {
            flex-direction: column-reverse;
            margin: 0 auto;
        }
        &__background-center {
            margin: auto 0;
            max-height: 228px;
            // max-width: 358px;
            img {
                border-radius: $border-section;
                height: 228px;
                margin-left: 15px;
                object-fit: cover;
                object-position: 100% 28%;
                width: 90%;
            }
        }
        &__decoration-right {
            bottom: -15px;
            height: 165px;
            position: absolute;
            right: 45px;
            width: 65px;
        }
    }
    .elipse {
        height: 490px;
        left: 50%;
        top: -377px;
        transform: rotate(0) translate(-50%);
        width: 490px;
    }
    .swiper-counter {
        transform: rotate(335deg);
    }
    .toIndex-2 > .swiper-counter {
        transform: rotate(-22deg);
    }
    .toIndex-3 > .swiper-counter {
        transform: rotate(-25deg);
    }
    .toIndex-4 > .swiper-counter {
        transform: rotate(-22deg);
    }
    .toIndex-5 > .swiper-counter {
        transform: rotate(-25deg);
    }
}

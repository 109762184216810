.team {
    position: relative;
    z-index: 2;
    &__img {
        margin-bottom: 80px;
        max-height: 800px;
        max-width: 1150px;
        position: relative;
        width: 60%;
        z-index: 4;
        img {
            border-radius: 0 $border-section $border-section 0;
            height: 100%;
            width: 100%;
        }
    }
    &__content {
        padding-bottom: 100px;
    }
}
@include media-breakpoint-between(md, lg) {
    .team {
        padding-bottom: 0;
        position: relative;
        z-index: 2;
        &__img {
            margin-bottom: 352px;
            position: relative;
            width: 90%;
            z-index: 4;
            img {
                border-bottom-right-radius: 15px;
                width: 100%;
            }
        }
        &__content {
            margin: 0 auto;
            padding-bottom: 10px;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .team {
        margin-bottom: -35px;
        padding-bottom: 0;
        position: relative;
        z-index: 2;
        &__img {
            margin-bottom: 250px;
            position: relative;
            width: 90%;
            z-index: 4;
            img {
                border-radius: 0 0 $border-section 0;
                width: 100%;
            }
        }
        &__content {
            margin: 0 auto;
            padding-bottom: 10px;
        }
    }
}

.side-nav {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: fixed;
    right: 15px;
    transform: none;
    width: 100px;
    z-index: 999;

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .js-side-nav-hoverable-section {
       display: flex;
       flex-direction: column;
    }

    .js-side-nav-hoverable-section.active {
        .side-nav__content {
            animation: fade-in 0.4s;
            opacity: 1;
            visibility: visible;
        }
    }
    &__cta {
        background-color: $theme-base;
        border: $none;
        border-radius: $half-radius;
        &:focus,
        &:active {
            border: $none;
            outline: $none;
        }
    }

    &__content {
        background: $side-nav;
        border-radius: $border-nav;
        display: none;
        margin: 0 auto;
        opacity: 0;
        padding: 16px 10px 0;
        transition: all 0.4s ease-in-out;
        visibility: hidden;
        z-index: 999;

        &__wrapper {
            position: relative;

            &__item {
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: all 0.4s ease-in-out;
            }
        }
    }

    &__content__wrapper:hover {
        .side-nav__content__wrapper__item {
            opacity: 1;
        }
    }
    &__content__wrapper__item__label {
        color: $color-white;
        padding: 0 45px 0 25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        &:hover {
            color: $color-white;
        }
    }
    &__desktop-icons {
        display: block;
    }
    &__mobile-icons {
        display: none;
    }
    &__email {
        cursor: pointer;
        opacity: 1;
        z-index: 555;

        img {
            height: 92px;
            object-fit: contain;
            object-position: center;
            width: 77px;
        }
    }
    &__close {
        cursor: pointer;
        display: none;
        height: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        transition: all 0.4s;

        &.active {
            display: block;
            height: auto;
            opacity: 1;
        }

        img {
            height: 88px;
            object-fit: contain;
            object-position: center;
            width: 72px;
        }
    }
    &__up {
        cursor: pointer;
        display: block;
        height: auto;
        opacity: 1;
        z-index: 555;
        img {
            height: 92px;
            object-fit: contain;
            object-position: center;
            width: 77px;
        }
    }
    &__dna {
        cursor: pointer;
        display: block;
        height: auto;
        opacity: 1;
        padding: 0;
        z-index: 555;

        &.hide {
            display: none;
        }

        img {
            height: 88px;
            object-fit: contain;
            object-position: center;
            width: 72px;
        }
    }

    @include media-breakpoint-between(md, lg) {
        &__content {
            &__wrapper__item {
                display: none;
            }
        }

        &__desktop-icons {
            display: none;
        }
        &__mobile-icons {
            display: block;
        }
    }

    @include media-breakpoint-between(xs, sm) {
        width: 80px;

        &__content {
            max-height: 55vh;
            padding: 15px 10px 1px 12px;

            &__wrapper {
                height: 60px;
                width: 60px;
            }
            &__wrapper__item {
                display: none;
            }
        }
        &__content__wrapper img {
            height: 100%;
            width: 100%;
        }
        &__desktop-icons {
            display: none;
        }
        &__mobile-icons {
            display: block;
        }
    }
}

.news {
    overflow: hidden;
    position: relative;
    &__background-center {
        margin: 160px 0;
        max-height: 720px;
        max-width: 971px;
        width: 55%;
        z-index: 2;
        img {
            border-radius: $border-section;
            height: 100%;
            width: 100%;
        }
    }
    &__decoration {
        left: 46px;
        max-height: 302px;
        max-width: 273px;
        position: absolute;
        top: 46px;
        z-index: 1;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__right-dec {
        bottom: -15px;
        max-height: 426px;
        max-width: 773px;
        position: absolute;
        right: 0;
        z-index: 1;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__content-container {
        @include blur-polyfill($color-gradient-white, 18px);

        border-radius: $border-section;
        height: 54%;
        left: 45%;
        max-width: 874px;
        padding: 3% 4% 5% 5%;
        position: absolute;
        top: 23%;
        width: 50%;
        z-index: 3;
        h1 {
            margin-bottom: 26px;
            opacity: 1;
            z-index: 5;
        }
        p {
            margin-bottom: 37px;
            opacity: 1;
            z-index: 5;
        }
    }
}
.latest-news {
    position: relative;
    top: 200px;
}
@include media-breakpoint-between(md, lg) {
    .news {
        padding-bottom: 50px;
        &__content-container {
            left: 50%;
            max-width: 750px;
            padding: 50px;
            position: relative;
            top: 73%;
            transform: translate(-50%, -65%);
            width: 100%;
        }
        &__background-center {
            margin: 90px 0 200px;
            width: 100%;
        }
        &__right-dec {
            bottom: 17px;
            max-height: 226px;
            max-width: 473px;
            position: absolute;
            right: 0;
            z-index: 1;
        }
        .bg--image {
            max-width: 100%;
        }
    }
    .latest-news {
        top: 230px;
    }
}

@include media-breakpoint-between(xs, sm) {
    .news {
        overflow: hidden;
        &__background-center {
            margin: 50px 10px;
            max-height: 302px;
            width: 100%;
        }
        &__decoration {
            left: 0;
            max-height: 128px;
            max-width: 116px;
            top: 0;
        }
        &__right-dec {
            max-height: 191px;
            max-width: 347px;
            position: absolute;
            right: -50px;
        }
    }
    .news__content-container {
        border-radius: $border-section-mobile;
        left: unset;
        margin-left: auto;
        max-width: 358px;
        padding: 24px 27px 44px 24px;
        position: static;
        top: -110px;
        transform: translate(10%, -20%);
        width: 96%;
        h1 {
            font-size: $font-large;
            margin-bottom: 20px;
        }
        p {
            width: 102%;
        }
    }
    .latest-news {
        top: -25px;
    }
}

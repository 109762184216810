.dna-introduction {
    border-bottom-left-radius: $border-card;
    height: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 0;
    &__scroll {
        bottom: 0;
        height: 40px;
        left: 0;
        position: absolute;
        width: 70px;
    }
    &__background {
        height: 133%;
        overflow-y: hidden;
        position: absolute;
        right: -17px;
        top: -85px;
        width: 85%;
        z-index: -1;
        img {
            height: 100%;
        }
    }
    &__content {
        height: 100%;
        position: absolute;
        top: 15vh;
        width: 34%;
        z-index: 1;

        &__wrapper {
            height: 45vh;
            position: relative;
        }
        .btn-primary {
            display: inline-block;
            margin-bottom: 20px;
            width: auto;
        }
        p {
            margin-bottom: 47px;
        }
    }

    &__content-title {
        margin-bottom: 26px;
    }
    &__content-description {
        margin-bottom: 37px;
    }

    .bg-image {
        z-index: -1;
    }
}
@include media-breakpoint-between(md, lg) {
    .dna-introduction {
        &__content {
            top: 25%;
            transform: translateY(-25%);
            width: 47%;

            &__wrapper {
                height: 55vh;
            }
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .dna-introduction {
        height: 700px;
        &__scroll {
            display: none;
        }
        &__content {
            top: 20px;
            width: 90%;

            p {
                margin-bottom: 25px;
            }
        }
        &__content-title {
            margin-bottom: 23px;
        }
        &__content-description {
            margin-bottom: 25px;
        }
        &__background {
            height: 96%;
            top: 213px;
            width: 181%;
        }
    }
}
@media (orientation: landscape) {
    @include media('S') {
        .dna-introduction {
            &__content {
                &__wrapper {
                    height: 100vh;
                }
            }
        }
    }
}
@media screen and (min-width: 1200px) and (max-height: 801px) {
    .dna-introduction {
        &__scroll {
            display: block;
            position: static;
        }
    }
}

.dna-model-container {
    min-height: 100%;
    canvas {
        min-height: 1100px;
    }
}

.model-debug-controls {
    position: absolute;
    z-index: 9999;
}

input,
textarea {
    background-color: $color-transparent;
    border: 2px solid $border-color;
    border-radius: $border-card;
    color: $color-transparent;
    margin-bottom: 2rem;
    opacity: 0.9;
    padding: 19px 32px 17px;
    width: 100%;
}
::placeholder {
    color: $color-plceh;
}
.textarea {
    margin-bottom: 2rem;
    min-height: 181px;
}
.input__form-recaptcha,
.input__form-agreement {
    margin-bottom: 38px;
}

.field {
    position: relative;

    &--checkbox {
        position: relative;

        input[type='checkbox'] {
            appearance: none;
            background: $theme-base;
            border: 0;
            height: 0;
            position: absolute;
            width: 0;

            &:checked + label::after {
                opacity: 1;
            }
            &.active + label::before {
                border-color: $color-error-msg;
            }
            &.active ~ .error-msg {
                display: block;
            }
        }
        label {
            cursor: pointer;
            display: flex;

            &::before {
                background-color: $color-blue-font;
                border: 1px solid $color-white;
                border-radius: $border-checkbox;
                content: '';
                display: inline-block;
                flex: 0 0 20px;
                height: 20px;
                margin-right: 1rem;
                position: relative;
                width: 20px;
            }
            &::after {
                color: $color-white;
                content: '\2713';
                cursor: pointer;
                font-size: $font-base;
                left: 0;
                line-height: 1.25;
                opacity: 0;
                position: absolute;
                text-align: center;
                top: 0;
                width: 20px;
            }
        }
    }
}
input[class='chk1'] + label {
    height: 22px;
    width: 22px;
}
.header__input {
    color: $color-blue-grey;
    padding: 13px;
}
.input__search {
    background-color: $color-white;
    border: 2px solid $border-search;
    border-radius: $border-card;
    color: $color-blue-grey;
    height: 53px;
    position: absolute;
    right: -4px;
    top: -20px;
    width: 100%;
}
.input__close {
    position: absolute;
    right: 11px;
    width: 15px;
    z-index: 25;
}
.error-input.active {
    border-color: $color-error-msg;
    position: relative;
}
.error-input.after {
    display: none;
}
.error-input.after.active {
    color: $color-error-msg;
    display: block;
    font-size: $font-medium;
    position: absolute;
    right: 50px;
    top: 15px;
}
.error-msg {
    color: $color-error-msg;
    display: none;
}
.error-msg.active {
    bottom: 5px;
    display: block;
    position: absolute;
}
.msg.error-msg.active {
    bottom: 10px;
    display: block;
    position: absolute;
}
.agreement.error-msg.active {
    bottom: 0;
    display: block;
    left: 0;
    margin: 0;
    position: static;
}

@include media-breakpoint-between(md, lg) {
    .header__pad > .header__icons-mobile {
        color: $color-blue-grey;
        left: 0;
        padding: 13px;
    }
    .input__search {
        width: 235px;
    }
}

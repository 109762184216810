@page {
    margin: 0;
    size: a4;
}
.print {
    margin-bottom: 14mm;
    min-height: 120mm;
    position: relative;
    &__section-title {
        left: 50%;
        max-height: 95.25mm;
        max-width: 85.52mm;
        padding: 13mm 6mm 0 ;
        position: absolute;
        top: 62mm;
        z-index: 3;
        h1 {
            font-size: $font-print-h;
            margin-bottom: 7mm;
        }
        &__p {
            opacity: 0.8;
        }
    }
    &__img {
        max-height: 115mm;
        position: relative;
        top: 34mm;
        width: 50%;
        z-index: 1;
        img {
            border-radius: 0 $border-section-p $border-section-p 0;
            height: 100%;
            width: 100%;
        }
    }
    &__decoration-right-top {
        max-height: 53mm;
        max-width: 53mm;
        position: absolute;
        right: 40%;
        top: 20mm;
     
    }
    &__accent-description {
        background-color: $color-white;
        font-size: $font-print-accent-p;
        padding: 18mm  5mm  18mm 0;
        position: relative;
    }
    &__accent-description-sizer {
        display: block;
        font-size: $font-print-p;
        margin: 0 15mm;
        max-width: 160mm;
    }
    &__decoration-left-top {
        height: 30.8mm;
        left: 5.3mm;
        opacity: 0.5;
        position: absolute;
        top: 5.3mm;
        width: 27.9mm;
    }
    &__print-logo {
        height: 22mm;
        left: 2.64mm;
        position: absolute;
        top: 2.64mm;
    }
}



.contacts {
    min-height: 470px;
    padding-top: 160px;
    z-index: -2;
    &__title {
        position: relative;
        width: 32%;
        z-index: 0;
        h1 {
            margin-bottom: 25px;
        }
    }
}
@include media-breakpoint-between(md, lg) {
    .contacts {
        padding-top: 42px;
        &__title {
            width: 52%;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .contacts {
        min-height: $unset;
        padding-top: 23px;
        &__title {
            width: 100%;
        }
    }
}

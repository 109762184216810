.slider-photo {
    max-height: 750px;
    padding-left: 112px;
    position: relative;

    // btn prev
    .swiper-button-next-photo,
    .swiper-photo .swiper-button-prev-photo {
        left: 45%;
        right: auto;
        top: 700px;
    }
    //btn next
    .swiper-button-prev-photo,
    .swiper-photo .swiper-button-next-photo {
        left: auto;
        right: 50%;
        top: 700px;
    }
    // container
    .swiper-photo .swiper-button-prev-photo,
    .swiper-photo .swiper-button-next-photo {
        position: absolute;
        top: 700px;
    }
    .swiper-container.swiper-photo {
        height: 724px;
        z-index: 3;
    }
}
@include media-breakpoint-between(md, lg) {
    .slider-photo {
        .swiper-container.swiper-photo {
            height: 574px;
        }
        //btn next
        .swiper-button-prev-photo,
        .swiper-photo .swiper-button-next-photo {
            left: auto;
            right: 57%;
            top: 700px;
        }
        .swiper-button-next-photo,
        .swiper-photo .swiper-button-prev-photo {
            left: auto;
            right: 65%;
            top: 700px;
        }
        // container
        .swiper-photo .swiper-button-prev-photo,
        .swiper-photo .swiper-button-next-photo {
            position: absolute;
            top: 550px;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .slider-photo .swiper-container.swiper-photo {
        height: 390px;
        left: 2%;
        width: 96%;
        z-index: 3;
    }
    .slider-photo {
        max-height: 390px;
        min-height: 200px;
        padding-bottom: 0;
        padding-left: 34px;
        .swiper-button-next-photo,
        .swiper-photo .swiper-button-prev-photo {
            left: 35%;
            right: auto;
            top: 356px;
        }
        .swiper-button-prev-photo,
        .swiper-photo .swiper-button-next-photo {
            left: auto;
            right: 43%;
            top: 356px;
        }
    }
}

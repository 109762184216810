// typography

.font {
    &--base {
        font-family: $roboto;
        font-size: $font-base;
    }
    &--medium {
        font-family: $roboto-medium;
        font-size: $font-medium;
    }
    &--large {
        font-size: $font-large;
    }
    &--xlarge {
        font-size: $font-xlarge;
    }
    &--bold {
        font-family: $roboto-bold;
    }

    &--pt-bold {
        font-family: $pt-sans-bold;
    }
    &--thin {
        font-weight: 700;
    }
    &--black {
        color: $color-black;
    }
    &--blue {
        color: $color-blue;
    }
    &--turquoise {
        color: $color-turquoise;
    }
    &--light-blue {
        color: $color-blue-light;
    }
    &--night-blue {
        color: $color-blue-font;
    }
    &--blue-grey {
        color: $color-blue-grey;
    }
    &--white {
        color: $color-white;
    }
    &--white-opacity {
        color: $color-white;
        opacity: 0.8;
    }
    &--red {
        color: $color-red;
    }
    &--grey {
        color: $color-grey;
    }
    &--blue-black {
        color: $color-blue-dark;
    }
}
@include media-breakpoint-between(xs, sm) {
    .font {
        &--medium {
            font-size: $font-medium-ip;
        }
    }
}

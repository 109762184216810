.section-title {
    padding-top: 66px;
    position: absolute;
    width: 55%;
    z-index: 1;
    &__p {
        opacity: 0.8;
    }
    &--left {
        background: $color-gradient-secondary;
        border-bottom-right-radius: $border-section;
        border-top-right-radius: $border-section;
        bottom: 0;
        height: 920px;
        padding-left: 6%;
        padding-right: 13%;
        right: 45%;
        top: 0;
        transform: translate(0, -6%);
        h1 {
            margin-bottom: 26px;
        }
        p {
            opacity: 0.8;
        }
    }

    &--left-photo {
        background: $color-gradient-secondary;
        border-bottom-right-radius: $border-section;
        border-top-right-radius: $border-section;
        min-height: 828px;
        padding-left: calc((100vw - 1640px) / 2);
        padding-right: 297px;
        right: 45%;
        top: -35px;
    }
    &--right {
        background: $color-gradient;
        border-bottom-left-radius: $border-section;
        border-top-left-radius: $border-section;
        bottom: 150px;
        height: 100%;
        left: 45%;
        padding-left: 88px;
        padding-right: 15%;
        h1 {
            margin-bottom: 26px;
        }
        p {
            opacity: 0.8;
        }
    }
    &--center {
        background: $color-gradient;
        border-bottom-left-radius: $border-section;
        border-top-left-radius: $border-section;
        height: 100%;
        max-height: 855px;
        padding-left: 17%;
        padding-right: 16%;
        padding-top: 10%;
        right: 0;
        top: 91px;
        width: 54%;
        z-index: 3;
        p {
            opacity: 0.8;
        }
        h1 {
            margin-bottom: 26px;
        }
    }
    &--center-secondary,
    &--center-secondary-news {
        background: $color-gradient-title;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
        height: 100%;
        max-height: 855px;
        padding: 10% 16% 0 17%;
        right: 0;
        top: 91px;
        width: 54%;
        z-index: 3;
        h1 {
            margin-bottom: 26px;
        }
        p {
            opacity: 0.8;
        }
    }
    &--center-top,
    &--center-top-secondary {
        @include blur-polyfill($color-gradient-section, 34px);
        
        border-radius: $border-section;
        bottom: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 660px;
        max-width: 700px;
        min-height: 560px;
        padding: 0 69px;
        right: 8%;
        top: 150px;
        vertical-align: middle;
        z-index: 3;
        h1 {
            margin-bottom: 26px;
        }
        p {
            opacity: 0.8;
        }
    }
    &--map {
        border-bottom-left-radius: $border-section;
        border-top-left-radius: $border-section;
        height: 800px;
        position: absolute;
        right: 0;
        top: -312px;
        width: 60%;
        z-index: 1;
    }
}
@include media-breakpoint-between(md, lg) {
    .section-title {
        &--left,
        &--right {
            padding-right: 50px;
            top: 0;
            transform: translate(0, -6%);
        }
        &--right {
            padding-top: 44px;
        }
        &--center,
        &--center-top,
        &--center-secondary-news,
        &--center-secondary {
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
            height: $unset;
            padding: 60% 15% 15% 11%;
            right: 0;
            top: 54px;
            width: 95%;
            z-index: 3;
        }
        &--center-top,
        &--center-top-secondary {
            bottom: 40px;
            padding: 0 49px;
            right: 35px;
            top: $unset;
            width: 95%;
            p {
                width: 80%;
            }
        }
        &--map {
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
            height: 400px;
            left: 34%;
            position: absolute;
            top: -202px;
            width: 66%;
            z-index: 1;
        }
        &--left-photo {
            min-height: 700px;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .section-title {
        &--map {
            height: 306px;
            left: 5%;
            position: absolute;
            top: -40px;
            width: 95%;
        }
        &--center-secondary,
        &--center,
        &--center-secondary-news {
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
            height: $unset;
            padding: 60% 6% 5% 11%;
            right: 0;
            top: 54px;
            width: 95%;
            z-index: 3;
            h1 {
                margin-bottom: 18px;
            }
        }
        &--center-top,
        &--center-top-secondary {
            bottom: $unset;
            left: 5%;
            min-height: 319px;
            padding: 34px 44px 24px;
            position: relative;
            top: -104px;
            vertical-align: middle;
            width: 90%;
        }
        &--right {
            bottom: unset;
            left: $unset;
            max-height: 586px;
            padding: 10% 5% 0 10%;
            right: 0;
            top: 0;
            transform: translate(0, -12%);
            width: 85%;
        }
        &--left {
            bottom: auto;
            height: 746px;
            left: 0;
            padding-left: 10%;
            padding-top: 10%;
            top: 0;
            transform: translate(0, -9%);
            width: 85%;
            h1 {
                font-size: $font-large;
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 30px;
            }
        }
        &--left-photo {
            left: 0;
            min-height: 435px;
            padding-left: calc((100vw - 388px) / 2);
            padding-right: 297px;
            right: 34%;
            top: -17px;
        }
    }
}

.footer {
    padding-top: 40px;
    position: relative;
    &__top-right {
        display: flex;
        flex-wrap: wrap;
    }
    &__top-partial {
        padding-right: 57px;
    }

    &__creative {
        display: flex;
        justify-content: flex-end;
        text-align: right;

        .pytek {
            margin-left: 10px;
        }
    }
    &__creative-start {
        display: flex;
        justify-content: flex-start;
     
    }
    &__iso-logo {
        margin-bottom: 0;
    }
    &__iso-p {
        margin-bottom: 0;
        margin-left: 10px
    }
    &__bottom {
        padding-bottom: 50px;
    }

    &__bottom > p > span {
        padding-right: 0;
    }
    &__list {
        color: $color-white;
        list-style: none;
        margin-bottom: 20px;
        opacity: 0.8;
        a {
            color: $color-white;
        }
    }
    &__list-none {
        color: $color-white;
        list-style: none;
        margin-bottom: 20px;
        opacity: 1;
        // text-align: center;
    }
    &__top {
        border-bottom: 1px solid $border-light;
        margin-bottom: 30px;
    }
    ul {
        padding-left: 0;
    }
}
@include media-breakpoint-between(md, lg) {
    .footer {
        overflow: hidden;
        padding-top: 50px;

        &__top__info {
            margin-bottom: 30px;
        }
        &__top {
            margin-bottom: 10px;
        }
        &__bottom {
            padding-bottom: 10px;
            text-align: center;
            /* stylelint-disable */
            &__row > * {
                font-size: 0.85rem;
            }
            /* stylelint-enable */
        }
        &__creative {
            justify-content: center;
            text-align: center;
            .pytek {
                max-width: 60px;
            }
        }
        &__creative-start {
            align-items: center;
            display: flex;
            justify-content: center;
        }
        &__list-none {
            margin-bottom: 10px;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .footer {
        overflow: hidden;
        padding-top: 100px;

        &__top {
            margin-bottom: 25px;
        }
        &__bottom {
            padding-bottom: 45px;
            text-align: left;
        }
        &__list {
            padding: 0 0.55rem;
        }
        &__bottom__row {
            flex-direction: column; 
            gap: 20px;
        }
        &__creative {
            justify-content: center;
            padding-bottom: 10px;
            text-align: left;
        }
        &__creative-start {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        li {
            width: auto;

            a {
                /* stylelint-disable-next-line */
                font-size: 0.875rem;
            }
        }
        ul {
            &.row {
                margin: 0 -0.55rem;
            }
        }
    }
}

.page {
    padding-bottom: 150px;
    padding-right: 15px;
    padding-top: 35px;
    &__position {
        justify-content: flex-end;
    }
}
.page__cube {
    color: $color-blue-grey;
    cursor: pointer;
    height: 40px;
    padding: 12px;
    text-align: center;
    width: 40px;
    &--light {
        color: $color-white;
    }
    img {
        height: 10px;
        width: 6px;
    }
}
.focused {
    background-image: $color-gradient-slider;
    border: 1px solid $border-light;
    color: $color-blue-light;
    margin-top: 8px;
    padding: 7px 2px 12px 0;
}
.focused-lt {
    background-image: $color-gradient-pagelt;
    border: 1px solid $border-light;
    color: $color-white;
    margin-top: 8px;
    padding: 7px 2px 12px 0;
}
@include media-breakpoint-between(md, lg) {
    .page {
        margin: 0 auto;
        padding-bottom: 0;
        padding-right: 15px;
        padding-top: 35px;
        width: 100%;
        &__position {
            justify-content: center;
        }
    }
}
@include media-breakpoint-between(xs, sm) {
    .page {
        padding-right: 0;
        padding-top: 0;
        &__position {
            justify-content: center;
        }
    }
}

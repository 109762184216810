// theme colors
$theme-color: #007aff;
$theme-base: transparent;
$theme-base-primary: #f6f8fa;
$border-search: #c5dff0;
$border-color: #a7969654;
$border-light: #66718e;
$background-plyr: #000d2f;
$background-accent: #f4f6f9;
$color-white: #fff;
$color-blue: #2682e7;
$color-blue-light: #258ad0;
$color-turquoise: #46c5f0;
$color-blue-dark: #214092;
$color-blue-font: #011343;
$color-blue-grey: #646e8d;
$color-grey: #606c8b;
$color-btn-hover: #1e6ca9;
$color-bullets: #cbdaef;
$color-paley-grey: #dddfe9;
$black: #000;
$color-black: #011343;
$color-red: rgba(236, 9, 9, 0.904);
$color-error-msg: #fd2d9f;
$color-success-msg: #2EF5BD;
$color-dropdown-border: #707070;
$color-gradient: linear-gradient(145deg, #1e6ca9 0%, #7a4d8b 100%);
$color-gradient-primary: linear-gradient(-107deg, rgba(0, 27, 87, 0.16) 0%, rgba(255, 255, 255, 0.1) 30%);
$color-gradient-secondary: linear-gradient(45deg, #7a4d8b 0%, #1e6ca9 100%);
$color-gradient-background: linear-gradient(90deg, rgba(36, 76, 155, 1) 0%, rgba(70, 197, 240, 1) 100%);
$color-gradient-card: linear-gradient(280deg, #fff 1%, rgb(70, 75, 77) 105%);
// start mozilla gradient
$color-gradient-white: linear-gradient(270deg,rgba(213, 224, 244, 0.44) 0,#fff 103%);
$color-gradient-blue: linear-gradient(50deg,rgba(0, 27, 87, 0.69) 50%,rgba(255, 255, 255, 0.38) 100%);
$color-gradient-section: linear-gradient(50deg,rgba(0, 27, 87, 0.89) 60%,rgba(255, 255, 255, 0.3) 100%);
$color-gradient-contact: linear-gradient(50deg,rgb(0, 27, 87) 55%,rgba(255, 255, 255, 0.28) 100%);
$color-gradient-map: linear-gradient(50deg,rgba(0, 27, 87, 0.94) 30%,rgba(255, 255, 255, 0.38) 100%);
$color-gradient-acc: #ffffffde;
// end
$color-gradient-blueblur: linear-gradient(107deg, rgba(20, 59, 139, 0.16) 0, rgba(255, 255, 255, 0.6) 30%);
$color-gradient-blueblur-card: linear-gradient(-19deg, rgba(0, 27, 87, 0) 0, rgba(255, 255, 255, 0.3) 80%);
$color-gradient-blueblur-element: linear-gradient(8deg, rgba(20, 59, 139, 0.4) 0, rgba(255, 255, 255, 0.09) 30%);
$color-gradient-interactive: linear-gradient(-107deg, rgba(0, 27, 87, 0.16) 0%, rgba(255, 255, 255, 0.2) 30%);
$color-gradient-presentation: linear-gradient(40deg, rgba(0, 27, 87, 96) 0, rgba(255, 255, 255, 0.13) 63%);
$color-gradient-title: linear-gradient(145deg, #1e6ca9 0%, #7a4d8b 151%);
$color-gradient-slider: linear-gradient(-18deg, rgba(0, 16, 51, 0.16) 0, rgba(255, 255, 255, 0.3) 103%);
$color-gradient-plyr: linear-gradient(0deg, rgba(0, 13, 47, 0.7) 0%, rgba(0, 13, 47, 0.7) 30%);
$color-gradient-pagelt: linear-gradient(4deg, rgba(33, 64, 146, 1) 0, rgba(37, 138, 208, 1) 67%);
$color-radial: radial-gradient(circle, rgba(33, 64, 146, 0.001) 0, rgba(121, 221, 255, 0.001) 59%);
$color-form: linear-gradient(-18deg, rgba(0, 16, 51, 0.4) 0, rgba(255, 255, 255, 0.3) 103%);
$color-forms: linear-gradient(-18deg, rgba(0, 16, 51, 0.5) 0, rgba(255, 255, 255, 0.18) 103%);
$color-input-card: linear-gradient(327deg, rgba(0, 16, 51, 0.6) 0, rgba(255, 255, 255, 0.2) 87%);
$color-input: linear-gradient(168deg, rgba(0, 64, 146, 0.4) 0, rgba(0, 16, 51, 0.8) 10%);
$color-gradient-accordion: linear-gradient(90deg, rgba(255,255,255,0.2976540958180147) 0%, rgba(20,59,139,0) 100%);
$color-plceh: rgba(255, 255, 255, 0.8);
$color-transparent: rgba(255, 255, 255, 0.11);
$font-primary: opensans-italic;
$roboto: roboto-regular;
$roboto-medium: roboto-medium;
$roboto-bold: roboto-bold;
$pt-sans-bold: ptsans-bold;
$pt-sans-regular: ptsans-regular;
$color-b: linear-gradient(141deg, rgba(255, 255, 255, 0.3) 0, rgba(0, 16, 51, 0.8) 110%);
$position-center: center;
$repeat: no-repeat;
$cover-size: cover;
$chevron-position: top right;
$unset: unset;
$none: none;
$font-print-p: 4.22mm;
$font-print-h: 6mm;
$font-print-accent: 20px;
$font-print-accent-p: 5.3mm;
$font-small: 13px;
$font-base: 16px;
$font-base-ip: 15px;
$font-medium: 20px;
$font-medium-ip: 18px;
$font-large: 23px;
$font-xlarge: 40px;
$font-xlarge-t: 34px;
$font-xlarge-ip: 24px;
$border-btn: 37px;
$border-card: 10px;
$border-min: 5px;
$border-checkbox: 4px;
$border-section: 15px;
$border-section-mobile: 15px 0 0 15px;
$border-section-p: 4mm;
$half-radius: 50%;
$tab-height: 70px;
$border-logo: rgba(145, 152, 167, 0.3);
$side-nav: linear-gradient(180deg, rgba(179, 103, 235, 0.3) 0%, rgba(37, 138, 208, 0.3) 100%);
$border-nav: 43px;

$chevron-nav-black: "../assets/images/icons-svg/header/expand_more_black.svg";
$chevron-nav-blue: "../assets/images/icons-svg/header/expand_more_blue.svg";
$chevron-nav-white: "../assets/images/icons-svg/header/expand_more_white.svg";

// Open Sans fonts

@font-face {
    font-family: $font-primary;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/OpenSans-Italic.ttf");
}
@font-face {
    font-family: $roboto;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/Roboto-Regular.ttf");
}
@font-face {
    font-family: $roboto-medium;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/Roboto-Medium.ttf");
}
@font-face {
    font-family: $roboto-bold;
    font-stretch: normal;
    font-style: bold;
    font-weight: bold;
    src: url("../assets/fonts/Roboto-Bold.ttf");
}
@font-face {
    font-family: $pt-sans-regular;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/PTSans-Regular.ttf");
}
@font-face {
    font-family: $pt-sans-bold;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    src: url("../assets/fonts/PTSans-Bold.ttf");
}


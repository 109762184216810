
.tab-section {
    background-color: $theme-base-primary;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 200px;
    position: relative;
    width: 100%;

    &__wrapper {
        margin: 0 auto;
        max-width: 90%;

        @include media-breakpoint-up(lg) {
            max-width: 75%;
        }
    }

    &__tabs {
        align-items: center;
        background-color: $color-blue-font;
        border-radius: $border-section $border-section 0 0;
        color: $color-white;
        display: flex;
        justify-content: center;
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
    }
    &__list-item {
        flex-grow: 1;
    }
    &__items {
        backdrop-filter: blur(14px);
        border: 1px solid $color-paley-grey;
        border-radius: $border-section $border-section;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            border-radius: 0 0 $border-section $border-section;
        }
    }
    &__item {
        background-image: $color-white;
        display: block;

        .content {
            height: 0;
            overflow: hidden;
            transition: opacity .6s;
        }

        &:last-child {
            .tab-section__tab--mobile {
                border-bottom: 0;
            }
        }

        &[data-active="true"] {
            .content {
                height: 100%;
                opacity: 1;
            }
        }
    }

    &__decoration {
        max-height: 320px;
        max-width: 324px;
        position: absolute;
        right: 5%;
        top: 168px;
        transform: translateX(50%);
        img {
            height: 100%;
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            right: 12.5%;
        }
    }

    &__tab {
        @include button-reset;

        color: $color-white;
        cursor: pointer;
        display: none;
        font-family: $pt-sans-bold;
        font-size: $font-medium-ip;
        margin: auto;
        padding: 2rem 0;

        &--mobile {
            background: $color-blue-font;
            border-bottom: 1px solid $color-white;
            color: $color-white;
            display: block;
            width: 100%;
        }

        &[data-active="false"] {
            font-family: $pt-sans-regular;

            @include media-breakpoint-up(lg) {
                opacity: 0.8;
            }
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            &--mobile {
                display: none;
            }
        }

        @media screen and (min-width: 1440px) {
            font-size: $font-large;
        }
    }
}
.btn-primary {
    background: $color-gradient;
    border-color: transparent;
    border-radius: $border-btn;
    font-family: $roboto-bold;
    padding: 18px 55px;
    width: 180px;
}
.btn-primary:hover {
    background: $color-btn-hover;
    cursor: pointer;
}
@include media-breakpoint-between(xs, sm) {
    .btn-primary {
        font-size: $font-base-ip;
        padding: 17px 50px;
        width: 150px;
    }
}

@mixin media($breakpoint) {
    @if $breakpoint == 'S' {
        @media all and (max-width: 1023px) {
            @content;
        }
    } @else if $breakpoint == 'M' {
        @media all and (max-width: 1024px) {
            @content;
        }
    } @else if $breakpoint == "L" {
        @media only screen and (min-width: 1024px) and (max-width: 1366px) {
            @content;
        }
    } @else if $breakpoint == "XL" {
        @media all and (max-width: 1440px) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint';
    }
}

@mixin button-reset {
    background-color: $theme-base;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 0;

    &:focus,
    &:hover,
    &:active {
        box-shadow: none;
        outline: none;
    }
}

@mixin blur-polyfill(
    $gradient,
    $blur,
    $support-gradient: linear-gradient(-107deg, rgba(0, 27, 87, 0.16) 0, rgba(255, 255, 255, 0.1) 30%)
) {
    /* stylelint-disable*/
    background-image: $gradient;
    border: none;

    /* if backdrop support: very transparent and blurred */
    @supports ((-webkit-backdrop-filter: blur(14px)) or (backdrop-filter: blur(14px))) {
        background-image: $support-gradient;
        -webkit-backdrop-filter: blur($blur);
        backdrop-filter: blur($blur);
        border: 1px solid $border-color;
    }
    /* stylelint-enable */
}

@include media-breakpoint-between(xs, lg) {
    .menu-btn.close .menu-btn__hamburger {
        background-color: $theme-base;
    }
    .menu-btn.close .menu-btn__hamburger::before {
        transform: rotate(45deg) translate(0, -5px);
    }
    .menu-btn.close .menu-btn__hamburger::after {
        transform: rotate(-45deg) translate(1px, 4px);
    }
    .menu-btn {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 30px;
        justify-content: center;
        position: relative;
        width: 30px;
    }
    .menu-btn__hamburger {
        background-color: $color-blue-font;
        content: '';
        height: 2px;
        position: absolute;
        transition: all 0.5s ease-in-out;
        width: 25px;
    }
    .menu-btn__hamburger::before {
        background-color: $color-blue-font;
        content: '';
        height: 2px;
        position: absolute;
        top: 3px;
        transform: translateY(-10px);
        transition: all 0.5s ease-in-out;
        width: 25px;
    }
    .menu-btn__hamburger::after {
        background-color: $color-blue-font;
        bottom: 3px;
        content: '';
        height: 2px;
        position: absolute;
        transform: translateY(10px);
        transition: all 0.5s ease-in-out;
        width: 25px;
    }

    .header__list {
        &.hovered {
            color: $color-blue;
        }
    }
}
